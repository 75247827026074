enum Category {
  GOVERNMENT_CRIMINAL_BLACKLIST = "Government Criminal Blacklist",
  DARKNET_MARKET = "Darknet Market",
  DARK_MARKET = "Dark Market",
  DARKNET = "Darknet",
  PHISHING_HACKING = "Phishing/Hacking",
  RANSOMWARE = "Ransomware",
  MONEY_LAUNDERING = "Money Laundering",
  BLACKMAIL = "Blackmail",
  SCAM = "Scam",
  TUMBLER = "Tumbler",
  MIXER = "Mixer",
  PARITY_BUG = "Parity Bug",
  PONZI_SCHEME = "Ponzi Scheme",
  BLOCKED = "Blocked",
  GAMBLING = "Gambling",
  DEFI = "DeFi",
  DEX = "DEX",
  BRIDGE = "Bridge",
  MEV_BOT = "MEV Bot",
  P2P_FINANCIAL_INFRASTRUCTURE_SERVICE = "P2P Financial Infrastructure Service",
  P2P_FINANCIAL_SERVICE = "P2P Financial Service",
  WALLET = "Wallet",
  CUSTODIAL_WALLET = "Custodial Wallet",
  CENTRALIZED_EXCHANGE = "Centralized Exchange",
  CYBER_SECURITY_SERVICE = "Cyber-Security Service",
  EXCHANGE = "Exchange",
  NFT_MARKETPLACE = "NFT Marketplace",
  MARKETPLACE = "Marketplace",
  CLOUD_MINING = "Cloud Mining",
  FAUCET = "Faucet",
  PAYMENT_PROCESSOR = "Payment Processor",
  MINING_POOL = "Mining Pool",
  E_COMMERCE = "E-Commerce",
  FUND = "Fund",
  FIAT_GATEWAY = "Fiat Gateway",
  OTHER = "Other",
}

export default Category;
